<template>
  <transition name="el-zoom-in-top" @after-leave="doDestroy">
    <div class="detail-container el-popper"
         v-show="showPopper"
         v-clickoutside="close"
         :style="{ width: `${dropdownWidth}px` }">

      <el-form class="fields-container" :model="addressObjectsValue" :rules="rules" ref="form">
        <template v-for="(field, index) in fields">
          <el-form-item :key="`${index}_`" :prop="field.name" class="input-field">
            <component
              :is="`${field.type}-field`"
              :label="$locale.gar_address_service.address_levels[field.name]"
              :type="field.name"
              :key="index"
              :address-type="type"
              v-model="addressObjectsValue[field.name]"
              @change="setValue(field.name, $event)"
              @open-popup="isPopupFieldOpened = true"
              @close-popup="isPopupFieldOpened = false"
            ></component>
          </el-form-item>
        </template>
        <TextField
            :label="$locale.gar_address_service.address_levels.description"
            class="input-field el-form-item__content"
            :type="'description'"
            v-model="addressObjectsValue.description"
            @change="setValue('description', $event)"
        ></TextField>
      </el-form>
      <div class="detail-container-buttons">
        <el-button size="mini" @click="clear">{{ $locale.main.button.clear }}</el-button>
        <el-button size="mini" type="primary" @click="save">{{ $locale.main.button.ok }}</el-button>
      </div>
    </div>
  </transition>
</template>

<script>
import Popper from 'element-ui/src/utils/vue-popper'
import Clickoutside from 'element-ui/src/utils/clickoutside'
import SelectField from '@/core/infrastructure/components/GarAddressField/SelectField'
import HouseField from '@/core/infrastructure/components/GarAddressField/HouseField'
import FlatField from '@/core/infrastructure/components/GarAddressField/FlatField'
import TextField from '@/core/infrastructure/components/GarAddressField/TextField'

import LEVELS_ADMINISTRATIVE from './levels-administrative.json'
import LEVELS_MUNICIPAL from './levels-municipal.json'

export default {
  name: 'address-objects',
  components: { SelectField, HouseField, FlatField, TextField },
  directives: { Clickoutside },
  mixins: [Popper],
  provide () {
    return {
      getPreviousValue: this.getPreviousValue
    }
  },

  props: {
    requiredLevel: {
      type: String
    },
    placement: {
      type: String,
      default: 'bottom-end'
    },
    isActive: {
      type: Boolean,
      default: false
    },
    dropdownWidth: {
      type: Number,
      default: 100
    },
    level: {
      type: Number
    },
    selectedAddress: {
      type: Object,
      default () {
        return {}
      }
    },
    type: {
      type: String,
      default: 'administrative'
    }
  },

  data () {
    return {
      addressObjectsValue: {
        address_id: null,
        subject: null,
        municipal_area: null,
        administrative_area: null,
        settlement: null,
        city: null,
        locality: null,
        planning_structure: null,
        street: null,
        house: null,
        flat: null,
        description: null // По умолчанию null, на сервере проверка дублей по NULL
      },
      address_id_flat: null,
      parent: this.$parent,
      isPopupFieldOpened: false,
      levelsAdministrative: [],
      levelsMunicipal: []
    }
  },

  computed: {
    rules () {
      let result = {}
      if (!this.requiredLevel) {
        return result
      }
      result[this.requiredLevel] = [
        { required: true, message: this.$locale.main.message.required_field }
      ]
      return result
    },
    fields () {
      let result = []
      this.levels.every(item => {
        result.push(item)
        return item.level !== this.level
      })

      return result
    },
    levels () {
      switch (this.type) {
        case 'administrative':
          return LEVELS_ADMINISTRATIVE
        case 'municipal':
          return LEVELS_MUNICIPAL
        default:
          console.log('error')
          return false
      }
    }
  },

  watch: {
    selectedAddress: {
      deep: true,
      handler: async function (value) {
        //господи ну какой же это костыль
        if (!value) {
          this.setValue('subject', null)
          this.setValue('address_id', null)
          return
        }
          await this.loadAdditionalAddressProperties(value.object_ids)
        //}
        if (value.house && !value.house.id) {
          this.setValue('house', value.house )
        }
        this.setValue('description', value.description)
        this.setValue('flat', value.flat)
      }
    },
    isActive (value) {
      this.showPopper = value
    }
  },

  mounted () {
    this.$parent.popperElm = this.popperElm = this.$el
    this.referenceElm = this.$parent.$refs.field.$refs.reference.$refs.input
  },

  updated () {
    this.$nextTick(_ => {
      this.popperJS && this.updatePopper()
    })
  },

  methods: {
    clear () {
      this.setValue('subject', null)
      this.$emit('set-address', null)
    },
    close () {
      if (!this.isPopupFieldOpened) {
        let isOpened = JSON.parse(JSON.stringify(this.isActive))
        setTimeout(() => {
          if (isOpened) {
            this.$emit('update:is-active', false)
          }
        }, 50)
      }
    },
    save () {
      this.$refs.form.validate(isValid => {
        if (isValid) {
          let lastElement = {}
          this.levels.forEach(item => {
            if (this.addressObjectsValue[item.name] && !item.ignore) {
              lastElement = {
                type: item.name,
                data: this.addressObjectsValue[item.name],
                level: item.level
              }
            }
          })

          if (Object.keys(lastElement || {}).length > 0) {
            // Данные об адресе существуют
            /* Other fields */
            this.$set(lastElement, 'flat', this.addressObjectsValue.flat)
            // По умолчанию null, на сервере проверка дублей по NULL
            this.$set(lastElement, 'description', this.addressObjectsValue.description || null)

            this.$set(lastElement, 'house', this.addressObjectsValue.house)
          }

          // Для привязки существующего адреса по id (не address_id). Новый адрес если заполнен description
          if (!lastElement.description /* Привязать к существующему адресу только при не заполненом description */ && this.address_id_flat) {
            this.$set(lastElement, 'id', this.address_id_flat)
          }
          /*
          if (lastElement.type === 'house') {
            lastElement.prevValue = this.getPreviousValue('house')
          }*/
          //this.$set(lastElement, 'addressObjectsValue', this.addressObjectsValue)
          //lastElement.addressObjectsValue = this.addressObjectsValue
          this.$emit('set-address', lastElement)
          this.close()
        }
      })
    },
    setValue (type, value) {
      this.$set(this.addressObjectsValue, type, value)
    },
    async loadAdditionalAddressProperties(objectIdsJSON) {
      let result = {}
      const objectIds = JSON.parse(objectIdsJSON.replace('\"', '"'))
      const existed_keys = Object.keys(objectIds)
      const keys = Object.keys(this.addressObjectsValue)
      for (let i = 0; i < keys.length; i++) {
        if (!existed_keys.includes(keys[i])) {
          continue
        }
        const key = keys[i]
        const value = objectIds[key]
        if (key === 'house') {
          const addressItemData = await this.$http.get(`${this.$config.api}/garaddressservice/houses/${value}`)
          this.setValue(key, addressItemData.data)
        } else if (['flat', 'description'].includes(key)) {
          this.setValue(key, value)
        } else {
          const addressItemData = await this.$http.get(`${this.$config.api}/garaddressservice/address_objects/${value}`)
          this.setValue(key, addressItemData.data)
        }
      }
      //this.$emit('additional-parameters-loaded', this.addressObjectsValue)
    },
    getPreviousValue (type) {
      let level = this.levels.find(item => item.name === type)
      let previousValue = null
      level.previous.forEach(item => {
        if (this.addressObjectsValue[item]) {
          previousValue = this.addressObjectsValue[item]
        }
      })

      return previousValue
    },
    flatChange (event) {
      this.address_id_flat = event?.address_id
    }
  }
}
</script>

<style scoped>
.detail-container {
  width: 100%;
  background: #F4F5F6;
  border: 1px solid #E9EBED;
  box-sizing: border-box;
  border-radius: 8px;
  padding-top: 2px;
  padding-bottom: 10px;
  z-index: 9999 !important; /* Фикс наложения модального окна над модальным окном быстрого добавления карточки */
}
.detail-container:focus {
  outline: none;
}

.input-field {
  margin: 5px 10px 0px 10px;
}

.fields-container {
  display: grid;
  grid-template-columns: 50% 50%;
}

.detail-container-buttons {
  float: right;
  margin-top: 10px;
  margin-right: 10px;
}
</style>

<style>
  .el-select-dropdown, .el-autocomplete-suggestion {
    /* Фикс наложения выпадающего списка el-autocomplete и el-select под модальным окном */
    z-index: 9999 !important;
  }
</style>
